

















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

export default class extends Vue{ 
  private sizeValue = null 
  private nameValue = null
  private fontsize = [{label: '12', value: '1'}, {label: '14', value: '2'},{label: '16', value: '3'},{label: '18', value: '4'},{label: '20', value: '5'},{label: '22', value: '6'}]
  private config = [
    {name: '上一级', data: 'undo', iconfont: '#iconchexiao'},
    {name: '下一级', data: 'redo', iconfont: '#iconzhongzuo'},
    {name: '加粗', data: 'Bold', iconfont: '#iconjiacu'}, 
    {name: '斜体', data: 'italic', iconfont: '#iconxieti'},
    {name: '下划线', data: 'underline', iconfont: '#iconxiahuaxian'}, 
    {name: '左对齐', data: 'justifyLeft', iconfont: '#iconjuzuo'}, 
    {name: '居中', data: 'justifyCenter', iconfont: '#iconjuzhong'},
    {name: '右对齐', data: 'justifyRight', iconfont: '#iconjuzuo'}
  ]
  private fontName = [
    {label: '宋体', value: 'SimSun'},
    {label: '楷体', value: 'KaiTi'}, 
    {label: '仿宋体', value: 'FangSong'}, 
    {label: '微软雅黑', value: 'Microsoft YaHei'}, 
    {label: '黑体', value: 'SimHei'}, 
    {label: '幼圆', value: 'YouYuan'},
    {label: '细明体', value: 'MingLiU'},
    {label: '标楷体', value: 'DFKai-SB'},
  ]
  private colorArr = [
    { value: 'red' },{ value: '#ff8c00' }, { value: '#ffd700' }, 
    { value: 'blue' }, { value: '#00ced1' }, { value: '#1e90ff' }, 
    { value: 'yellow' }, { value: '#90ee90' }, { value: '#c71585' }, 
    { value: 'orange' }, { value: '#e523a3' }, { value: 'rgba(255, 69, 0, 0.68)' }, 
    { value: 'pink' }, { value: 'rgb(255, 120, 0)' }, { value: '#c7158577' }, 
    { value: '#000' }, { value: '#ff4500' },
    { value: '#409EFF' }, { value: '#fff' },
  ]
  private editorClick(item, type) {
    item.value? document.execCommand(type, false, item.value) : document.execCommand(item.data)
  }
}
