




















import { Vue, Component, Prop } from 'vue-property-decorator';
import EditorComponent from "./editor.vue";
import uploadImgModel from "./../components/uploadImgModel.vue"

@Component({
  components: { EditorComponent },
})
export default class extends Vue{
  @Prop({default: () => ({})})styleValue?:Object
  @Prop({default: () => ([])})params: any
  @Prop({default: () => ('')})templateId?: String 
  @Prop({default: () => ([])})imgChioce?: Array<any>

  constructor(props) {
    super(props);
  }
  private baseApi = process.env.VUE_APP_BASE_URL
  private formStyle: any = {}
  private uploadImg () {
    this.$modal.create({
      component: uploadImgModel,
      title: '上传图标',
      width: 550,
      props: { templateId: this.$route.query.id}
    }).$on('close', () => {
      this.$emit('shapeReceive')
    })
  }
  private shape(item) {
    this.formStyle = this.styleValue
    this.formStyle.cssType = item.cssType
    this.params[this.formStyle.v] = this.formStyle
    this.axios.post('/admin/knowledgeTemplateModule/saveOrUpdate', this.formStyle, { headers: {'Content-Type':'application/json'} }).then((res: any) => {
      if(res.result) {
        this.$emit('shapeReceive')
      }else {
        this.$message.error(res.msg)
      }
    })
  }
}
