
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
})
export default class extends Vue{
  @Prop({default: () => ('')})templateId?: String
  private action = process.env.VUE_APP_BASE_URL
  private dialogFormVisible = false
  private uploadAction = ''
  private formStyle: any = {}
  private fileList = []
  private removeFileHandle(file, fileList) {
    this.fileList = fileList;
  }
  private uploadSuccess(response, file, fileList) {
    this.fileList = fileList.map(i => {
      i.filePath = i.response && i.response.json.filePath; 
      i.templateId = this.templateId; 
      return i 
    })
  };
  private save () {
    if (!this.fileList) {
      this.$message.warning('请上传图片！');
      return;
    } else if (!this.fileList.every((i: any) => { return i.status === "success"})) {
      this.$message.warning('请等待所有图片上传完成！');
      return;
    }else {
      this.axios.post('/admin/icon/batchInsert', this.fileList, { headers: {'Content-Type': 'application/json'} }).then((res: any) => {
        if (res.result) {
          this.$message.success('上传成功');
          this.$emit('close');
        }
      })
    }
  }
}
