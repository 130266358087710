



















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import editDialog from './dialog.vue'
import styleComponent from './styleComponent.vue'

@Component({
  name: "editModel",
  components: { styleComponent },
})
export default class extends Vue{
  private baseApi = process.env.VUE_APP_BASE_URL
  private visible = false 
  private params: any = []
  private loading = false
  private checkList = [
    {label: '标题', id: '1'}, {label: '段落', id: '2'}, {label: '知识点', id: '3'}, {label: '例题', id: '4'}, 
    // {label: '试卷', id: '5'}, {label: '学生错题', id: '6'}, {label: '思维导图', id: '7'}
  ]
  private mounted() {
    let dom: any = document.getElementsByClassName('container')[0];
    dom.style.height = "100%" //滚动条
    this.init()
  }
  private destroyed() {
    let dom: any = document.getElementsByClassName('container')[0];
    dom.style.height = ""
  }
  private imgChioce = []
  private init() {
    this.loading = true
    this.params.map((v) => {
      v.visibleAddTitle = false
    }) //初始化恢复默认值
    let param ={
      pid: this.$route.query.id
    }
    this.axios.post('/admin/knowledgeTemplateModule/query',{ ...param } ).then((res:any) => {
      this.params = res.json
      this.loading = false
    })
    let subject: any = this.$route.query
    if (subject) {
      this.axios.post('/admin/icon/queryIcon', { subject: subject.code}).then((res: any) => {
        if (res.result) {
          this.imgChioce = res.json
        } else {
          this.$message.error(res.msg);
        }
      })
    }
  }
  private shapeReceive (e) {
    this.init()
  }
  // 返回值计算
  private subtypeCom (data) { 
    if(data && typeof (data) !== 'string') {
      data = data.join(',')
    }
    let subtype: any = []
    data && data.split(',').map((v) => {
      this.checkList.map((v1) => {
        if(v === v1.id) {
          subtype.push(v1.label)
        }
      })
    })
    return subtype.join('，')
  }
  private visibleAdd(key) {
    key.visibleAddTitle = !key.visibleAddTitle
    this.params.map((v) => {
      v.styleLight = false
    })
    this.styleValue = this.params[this.titleIndex]
  }
  private edit(key, index) {
    this.visible = false
    this.$modal.create({
      component: editDialog,
      title: '新增标题',
      width: 550,
      props: { pid: this.$route.query.id, level: 1, sort: 0 },
    }).$on('close', (e) => {
      this.visible = false
      this.init()
    })
  }

  private editTitle(key, index) {
    key.type = 'edit'
    key.visibleAddTitle = false
    this.$modal.create({
      component: editDialog,
      title: '编辑',
      width: 550,
      props: { data: key },
    }).$on('close', (e) => {
      this.params[index].type = ''
      this.init()
    })
  }
  private addFirst(key, index) {
    key.visibleAddTitle = false
    this.$modal.create({
      component: editDialog,
      title: '添加标题',
      width: 550,
      props: { pid: this.$route.query.id, level: 1, sort: key.sort },
    }).$on('close', (e) => {
      this.init()
    })
  }
  private addSecond(key, index) {
    key.visibleAddTitle = false
    this.$modal.create({
      component: editDialog,
      title: '添加二级标题',
      props: { pid: this.$route.query.id, level: 2, sort: key.sort},
      width: 550,
    }).$on('close', (e) => {
      this.init()
    })
  }
  private deleteTitle(key) {
    key.visibleAddTitle = false
    if(this.params.length <= 1) {
      this.$message({
        type: 'warning',
        message: '至少有一个标题!'
      });
    }else {
      this.$confirm('删除该内容后不可恢复, 确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post('/admin/knowledgeTemplateModule/deleteById/' + key.id).then((res: any) => {
          if(res.result) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.init()
          }
        })
      })
    }
  }
  private styleValue: any = {  }
  private editorBlur(e, item, v) {
    let inner = e.target.innerHTML
    let editorItem = JSON.parse(JSON.stringify(item))
    if (item.cssContent !== null) {
      editorItem.cssContent = inner   
    } else {
      item.cssContent = item.title
      item.title = ''
    }
    this.axios.post('/admin/knowledgeTemplateModule/saveOrUpdate', editorItem, { headers: {'Content-Type':'application/json'}})
  }
  private titleIndex = 0 //用来存放角标
  private titleEdit(key, v) {
    key.v = v 
    this.titleIndex = v
    key.styleLight = !key.styleLight 
    this.styleValue = key
  }
}
