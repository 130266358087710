















import { Vue, Component, Prop } from 'vue-property-decorator';


@Component({
  components: {  },
})
export default class extends Vue{
  @Prop() pid?: String
  @Prop() level?: Number
  @Prop() data?: any
  @Prop() sort?: any
  private param: any = {
    title: '',
    subtype: ["1","2","3","4"],
    visibleAddTitle: false,
    styleLight: false,
  }
  private created() {
    if(this.data && this.data.type === 'edit') {
      if(typeof (this.data.subtype) !== 'string') {
        this.data.subtype = this.data.subtype.join(',')
      }
      this.param = this.data
      this.param.subtype = this.data.subtype.split(',')
    }
  }
  private checkList = [
    { label: '标题', id: '1' }, { label: '段落', id: '2' }, { label: '知识点', id: '3' }, { label: '例题', id: '4' }, 
    // { label: '试卷', id: '5' }, { label: '学生错题', id: '6' }, { label: '思维导图', id: '7' }
  ]
  private save() {
    if(this.param.title === ''){
      this.$message({message: '请输入名称', type: 'warning'});
    }else if(this.param.subtype.length === 0) {
      this.$message({message: '请选择支持添加的内容', type: 'warning'});
    }else {
      this.param.sort = this.sort + 1
      this.param.subtype = this.param.subtype.join(',')
      this.param.pid = this.pid
      this.param.level = this.level
      if(this.level === 1) {
        this.param.cssType = 1
      }else if(this.level === 2) {
        this.param.cssType = 2
      } //默认样式
      this.param.cssContent = null
      this.axios.post('/admin/knowledgeTemplateModule/saveOrUpdate', {...this.param},{ headers: {'Content-Type':'application/json'}}).then((res: any) => {
        if(res.result){ 
          this.$message.success('保存成功~！'); 
          this.$emit('close', this.param);
        }else { 
          this.$message.error('保存失败~！')
        }
      })
    }
  }
}
